export const getQueryParams = (): any => {
    const pathName = decodeURIComponent(window.location.pathname);
    const queryString = decodeURI(window.location.search) || pathName.substr(pathName.indexOf('?'), pathName.length - 1);
    return queryString
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce((values, [key, value]) => {
            values[key] = value;
            return values;
        }, {});
};