
import { ICategoryHierarchyViewProps, IRefineMenuViewProps, ISearchResultContainerViewProps, ISearchResultModalViewProps, ISortByViewProps, ITitleViewProps } from '@msdyn365-commerce-modules/search-result-container';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { getQueryParams } from '../../../helpers/query-param-helpers';
import * as React from 'react';

const SearchResultContainerView: React.FC<ISearchResultContainerViewProps> = props => {
    const { SearchResultContainer, products, pagination, ProductsContainer, ProductSectionContainer, isMobile, modalToggle, searchResultModal, TitleViewProps,
        refineMenu, categoryHierarchy, sortByOptions, CategoryNavContainer, RefineAndProductSectionContainer, errorMessage } = props;

    const searchTerm = getQueryParams().q; // Query param for search term is just the letter 'q'

    const noResultsMessage = (
        <span className='ms-search-result-container__no-results-message'>
            <p className='error-text'>No products found for the given search term: <span className='error-text--bold'>"{searchTerm}"</span>. Perhaps try a different term or brand?</p>
            <p className='error-text contact-us-blurb'>Can’t find what you’re looking for? Not all products are available online currently.
            Please call us at <a className='error-text--bold' href='tel: 0800 200 600'>0800 200 600</a> or click the link below to email us.</p>
            <a className='contact-us-link' href='https://www.farmlands.co.nz/Contactus'>Contact us</a>
        </span>
    );

    if (isMobile) {
        return (
            <Module {...SearchResultContainer}>
                {renderCategoryHierarchy(categoryHierarchy)}
                {renderTitle(TitleViewProps)}
                {/* {choiceSummary} */}
                {!errorMessage && modalToggle}
                {createSearchResultModal(searchResultModal, refineMenu, sortByOptions)}
                <Node {...ProductsContainer}>
                    {errorMessage && noResultsMessage}
                    {products}
                </Node>
                <div className='ms-search-result-container__pagination-container'>
                    {pagination}
                </div>
            </Module>
        );
    }
    return (
        <Module {...SearchResultContainer}>
            <Node {...CategoryNavContainer}>
                {categoryHierarchy && renderCategoryHierarchy(categoryHierarchy)}
            </Node>
            <Node {...RefineAndProductSectionContainer}>
                {refineMenu && renderRefiner(refineMenu)}
                <Node {...ProductSectionContainer}>
                    <div className='ms-search-result-wrapper-title-choice-summary'>
                        {TitleViewProps && renderTitle(TitleViewProps)}
                        {/* {choiceSummary} */}
                    </div>
                    {!errorMessage && <div className='ms-search-result-wrapper-sort-by-category'>
                        {sortByOptions && renderSort(sortByOptions)}
                    </div>}
                    <Node {...ProductsContainer}>
                        {errorMessage && noResultsMessage}
                        {products}
                    </Node>
                    <div className='ms-search-result-container__pagination-container'>
                        {pagination}
                    </div>
                </Node>
            </Node>
        </Module>
    );

};

const createSearchResultModal = (modalProps: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps): JSX.Element => {
    return React.cloneElement(modalProps.modal, {}, modalProps.modalHeader, createModalBody(modalProps, refineMenu, sortByDropDown), modalProps.modalFooter);
};

const createModalBody = (props: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps): JSX.Element | null => {
    if (sortByDropDown) {
        return React.cloneElement(props.modalBody, {}, renderSort(sortByDropDown), renderRefiner(refineMenu));
    }
    return null;
};

const renderRefiner = (props: IRefineMenuViewProps): JSX.Element | null => {
    const { refiners, RefineMenuContainer, RefinerSectionContainer } = props;
    if (refiners) {
        return (
            <Node {...RefinerSectionContainer}>
                <Node {...RefineMenuContainer}>
                    {refiners.map((submenu, index) => (
                        <React.Fragment key={index}>
                            {submenu}
                        </React.Fragment>
                    ))}
                </Node>
            </Node>
        );
    }
    return null;
};

const renderSort = (props: ISortByViewProps): JSX.Element | null => {
    const { SortingContainer, sortByDropDown } = props;
    if (sortByDropDown) {
        return (
            <Node {...SortingContainer}>
                {sortByDropDown}
            </Node>
        );
    }
    return null;
};

const renderCategoryHierarchy = (props: ICategoryHierarchyViewProps): JSX.Element | null => {
    const { CategoryHierarchyContainer, categoryHierarchyList, categoryHierarchySeparator } = props;
    if (categoryHierarchyList) {
        return (
            <Node {...CategoryHierarchyContainer}>
                {categoryHierarchyList.map((category, index) => (
                    <React.Fragment key={index}>
                        {category}
                        {categoryHierarchyList && categoryHierarchyList[index + 1] && categoryHierarchySeparator}
                    </React.Fragment>
                ))}
            </Node>
        );
    }

    return null;
};

const renderTitle = (props: ITitleViewProps): JSX.Element | null => {
    const { title, TitleContainer } = props;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <h2>
                    {title.titleCount}
                </h2>
            </Node>
        );
    }
    return null;
};

export default SearchResultContainerView;